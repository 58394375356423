// https://github.com/onurzorluer/react-image-file-resizer

import Resizer from "react-image-file-resizer"

const resizeImage = (file) =>
  new Promise((resolve, reject) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]

    if (!allowedTypes.includes(file.type)) {
      reject(new Error("File should be one of jpg, jpeg, png"))
      return
    }

    if (file.size > 10 * 1024 * 1024) {
      // 10MB
      reject(new Error("File size is too large"))
      return
    }

    Resizer.imageFileResizer(
      file,
      900,
      900,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      "file",
      600,
      600,
    )
  })

export default resizeImage
