import { Box } from "@mui/material"

import useAppRequest from "hooks/use_app_request"
import { forwardRef } from "react"

import StepBlock from "components/shared/StepBlock"
import Term from "components/shared/fields/Term"
import CarPrice from "components/shared/fields/CarPrice"
import CarDownPayment from "components/shared/fields/CarDownPayment"
import useAppStore from "store"
import { FINANCING_PRODUCTS } from "../../../../config/validations"

const LoanParameters = forwardRef((props, ref) => {
  const stepName = "loan_parameters"
  const application_type_id = useAppStore((state) => state.fields?.application_type_id)

  const { handleSubmit } = useAppRequest(stepName, {
    term: "term",
    car_price: "car_info.price",
    car_down_payment: "car_info.down_payment",
  })

  const isFinancingProduct = FINANCING_PRODUCTS.includes(application_type_id)

  return (
    <StepBlock stepName={stepName} stepTitle="Preferrable loan parameters">
      <Box sx={{ maxWidth: "388px" }} component="form" onSubmit={handleSubmit} ref={ref}>
        <Term />
        {isFinancingProduct && <CarPrice />}
        {isFinancingProduct && <CarDownPayment />}
      </Box>
    </StepBlock>
  )
})

LoanParameters.displayName = "LoanParameters"

export default LoanParameters
