import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import InputMask from "react-input-mask"

const CompanyLengthService = () => {
  const id = "company_length_service"

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  const customHandleChange = (event) => {
    let { value } = event.target

    if (/^0/.test(value) && value.length > 1) {
      value = value.replace(/^0/, "")
    }

    const modifiedEvent = {
      ...event,
      target: { ...event.target, value: value },
    }

    handleChange(modifiedEvent)
  }

  return (
    <InputMask
      mask="99"
      maskPlaceholder=""
      value={inputValue}
      onChange={customHandleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage || "Enter how many years you have worked at the specified company"}
    >
      <MyTextField id={id} _label="Length of Service" inputProps={{ inputMode: "numeric" }} />
    </InputMask>
  )
}

export default CompanyLengthService
