import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import useAppStore from "store"
import { FINANCING_PRODUCTS } from "config/validations"

const CarPlateNumber = () => {
  const id = "car_plate_number"
  const { application_type_id } = useAppStore((state) => state.fields)

  const validate = (value) => {
    if (FINANCING_PRODUCTS.includes(application_type_id)) return ""

    if (value === "") return "This field is required"

    if (value.length < 1) return "Is too short (minimum is 1 character)"
    if (value.length > 12) return "Is too long (maximum is 12 characters)"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <MyTextField
      id={id}
      _label="Plate number"
      placeholder="Plate number"
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    />
  )
}

export default CarPlateNumber
