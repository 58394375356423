import { useEffect } from "react"
import { Box, Typography } from "@mui/material"

import ApplicationLayout from "components/views/application_partner/Layout"
import StepsLayout from "components/views/application_partner/steps/Layout"
import MobilePhone from "components/shared/fields/MobilePhone"
import ProductType from "components/shared/fields/ProductType"
import SubmitButton from "components/shared/buttons/Submit"

import useAppStore from "store"
import useNavigateSearch from "hooks/use_navigate_search"
import useClientStore from "../../../store/client"
import { FINANCING_PRODUCTS } from "../../../config/validations"

const NewApp = () => {
  const stepName = "create_application"

  const resetState = useAppStore((store) => store.resetState)
  const resetClient = useClientStore((store) => store.resetClient)
  const updateKey = useAppStore((state) => state.updateKey)
  const updateSteps = useAppStore((state) => state.updateSteps)
  const updateErrorMessage = useAppStore((state) => state.updateErrorMessage)
  const updateUtmParam = useClientStore((store) => store.updateUtmParam)
  const errorMessages = useAppStore((state) => state.error_messages)
  const createApp = useAppStore((state) => state.createApp)
  const { mobile_phone, application_type_id } = useAppStore((state) => state.fields)
  const utmParams = useClientStore((state) => state.utm_params)

  const navigateSearch = useNavigateSearch()

  const syncQueryParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    updateUtmParam("utm_source", params.utm_source)
    updateUtmParam("utm_medium", params.utm_medium)
    updateUtmParam("utm_campaign", params.utm_campaign)
    updateUtmParam("utm_term", params.utm_term)
    updateUtmParam("utm_content", params.utm_content)
    updateUtmParam("click_id", params.click_id || params.clickid || params.clickId)

    if (!["mfast", "carmundi", "motorbikesph", "localemployee", "agent"].includes(params.utm_source)) {
      navigateSearch("/autoloan")
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    syncQueryParams()
    resetState()
    resetClient()
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    if (errorMessages["mobile_phone"].length > 0 || errorMessages["product_type"] > 0) return

    updateKey("backdrop", true)

    const params = {
      step_name: stepName,
      [stepName]: {
        mobile_phone: mobile_phone,
        application_type_id: application_type_id,
        utm_params: utmParams,
      },
    }

    const appSteps = {
      general_info: null,
      residence: null,
      government_issued_id: null,
      references: null,
      financial_info: null,
      car_general_info: null,
      loan_parameters: null,
    }

    const isFinancingProduct = FINANCING_PRODUCTS.includes(application_type_id)

    createApp(params)
      .then((response) => response?.data)
      .then((data) => {
        updateKey("uuid", data?.uuid)

        updateKey("current_step", "general_info")

        if (isFinancingProduct) {
          updateSteps(appSteps)
          navigateSearch("/autoloan/application/partner")
        } else {
          navigateSearch("/autoloan/application/full")
        }
      })
      .catch((error) => {
        const data = error.response.data

        if (data.error_messages) {
          const errorMobilePhone = (data.error_messages.mobile_phone && data.error_messages.mobile_phone[0]) || ""
          const errorProductType =
            (data.error_messages.application_type_id && data.error_messages.application_type_id[0]) || ""

          if (errorMobilePhone === "Unknown number") {
            navigateSearch("/autoloan/application/full/success")
          } else {
            updateErrorMessage("mobile_phone", errorMobilePhone)
            updateErrorMessage("application_type_id", errorProductType)
          }
        }
      })
      .finally(() => {
        updateKey("backdrop", false)
      })
  }

  return (
    <ApplicationLayout>
      <StepsLayout>
        <Typography variant="h4" component="h4" sx={{ mb: { xs: 2, md: 2.5 } }}>
          Application form
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <MobilePhone />
          <ProductType />

          <Box sx={{ mt: 3.5, display: "flex", justifyContent: "flex-end" }}>
            <SubmitButton text="Next step" type="submit" />
          </Box>
        </Box>
      </StepsLayout>
    </ApplicationLayout>
  )
}

export default NewApp
