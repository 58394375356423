import InputMask from "react-input-mask"

import BaseField from "components/base/TextField"
import useInput from "hooks/use_input"

const DriveLicense = () => {
  const id = "dl_document_number"

  const mask = "a99-99-999999"
  const validate = (value) => {
    if (value === "") return "This field is required"

    if (value.replace(/[^a-zA-Z0-9]/g, "").length < 11 || value === "N12-34-567890")
      return "Please enter valid ID number"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  return (
    <InputMask
      required
      maskPlaceholder="_"
      mask={mask}
      // alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={
        errorMessage ||
        "Driver's license number consists of 11 symbols starting with a letter. For example, N12-34-567890"
      }
    >
      <BaseField id={id} _label="Driving License number" />
    </InputMask>
  )
}

export default DriveLicense
