import { useEffect, useState } from "react"
import BaseSelect from "components/base/Select"

import useSelect from "hooks/use_select"
import useAppStore from "store"

const TransportType = () => {
  const id = "car_transport_type_id"
  const [transportTypes, setTransportTypes] = useState([])
  const [loaded, setLoaded] = useState(false)

  const getTransportTypes = useAppStore((state) => state.getTransportTypes)

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleOpen, handleClose, open } = useSelect(id, validate)

  useEffect(() => {
    fetchTransportTypes()
  }, [])

  const fetchTransportTypes = () => {
    getTransportTypes()
      .then((response) => response.data)
      .then((data) => {
        setTransportTypes(data)
        setLoaded(true)
      })
  }

  return (
    <BaseSelect
      id={id}
      label="Unit type"
      placeholder="Select unit"
      error={errorMessage?.length > 0}
      value={loaded ? inputValue : ""}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      items={transportTypes}
      helperText={errorMessage}
    />
  )
}

export default TransportType
