import { InputAdornment } from "@mui/material"

import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import InputMask from "react-input-mask"

const MonthlyIncome = () => {
  const id = "monthly_income"

  const validate = (value) => {
    if (value === "") return "This field is required"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur } = useInput(id, validate)

  const customHandleChange = (event) => {
    let { value } = event.target

    if (/^0/.test(value) && value.length > 1) {
      value = value.replace(/^0+/, "")

      if (value === "") {
        value = "0"
      }
    }

    const modifiedEvent = {
      ...event,
      target: { ...event.target, value: value },
    }

    handleChange(modifiedEvent)
  }

  return (
    <InputMask
      mask="999999"
      maskPlaceholder=""
      value={inputValue}
      onChange={customHandleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      helperText={errorMessage}
    >
      <MyTextField
        id={id}
        _label="Monthly Income"
        InputProps={{
          startAdornment: <InputAdornment position="start">₱</InputAdornment>,
        }}
        inputProps={{ inputMode: "numeric" }}
      />
    </InputMask>
  )
}

export default MonthlyIncome
