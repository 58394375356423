const ENABLE_VALIDATIONS = true
const FINANCING_PRODUCTS = ["auto_financing", "moto_financing"]

const validateNumbers = (value) => {
  const numbers = value.replace(/\D+/g, "").split("") // Remove non-digits and split into array
  const maxSerialCount = 4 // Maximum allowed sequential digits (e.g., 12345)
  const maxSameCount = 3 // Maximum allowed identical consecutive digits (e.g., 1111)

  let serialCount = 0 // Counter for sequential digits
  let sameCount = 0 // Counter for identical consecutive digits

  // Use reduce to iterate through the array and track counts
  const isValid = numbers.slice(1).every((num, i) => {
    const prevNum = numbers[i]

    // Check for sequential digits
    if (num - prevNum === 1) {
      serialCount += 1
      if (serialCount > maxSerialCount) return false // Exceeds sequential limit
    } else {
      serialCount = 0 // Reset sequential counter
    }

    // Check for identical consecutive digits
    if (num === prevNum) {
      sameCount += 1
      if (sameCount > maxSameCount) return false // Exceeds identical limit
    } else {
      sameCount = 0 // Reset identical counter
    }

    return true // Continue iteration
  })

  return isValid
}

const checkDocumentFormat = (id, value) => {
  const number = value.replace(/[^a-zA-Z0-9]+/g, "")
  let validNumber = false

  const validationRules = {
    5: /^([a-zA-Z])([0-9]{10})$/,
    18: /^[0-9]{16}$/,
    6: /^[0-9]{7}$/,
    3: /^([a-zA-Z]{2}\d{7}|[a-zA-Z]{1}\d{7}[a-zA-Z]{1})$/,
    4: /^[0-9]{12}$/,
    20: /^PCN[0-9]{16}$/,
    21: /^PRN\w{2}\d{10}[a-zA-Z]$/,
    1: /^[0-9]{10}$/,
    2: (num) => /[0-9]{9}/.test(num) && (num.replace(/\D/g, "").length === 9 || num.replace(/\D/g, "").length === 12),
    16: /^CRN([0-9]{12})$/,
    7: (num) => /(\w{22}|\w{23})/.test(num.replace("VIN", "")) && num.length >= 22,
  }

  if (validationRules[id]) {
    if (typeof validationRules[id] === "function") {
      validNumber = validationRules[id](number)
    } else {
      validNumber = validationRules[id].test(number)
    }
  }

  return validNumber
}

const checkNotRealNumber = (id, value) => {
  const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "")

  const notRealNumbers = {
    18: ["1234123412341234"],
    6: ["1234567"],
    3: ["P1234567A", "EC1234567"],
    4: ["121234567891"],
    20: ["PCN1234567891011213"],
    21: ["PRN100141234567P"],
    1: ["1234567890"],
    2: ["123123123", "123123123000"],
    16: ["CRN123412345671"],
    7: ["VIN12341234AA1234ABC123451", "VIN12341234AA1234ABC12345"],
  }

  return notRealNumbers[id] && notRealNumbers[id].includes(filteredValue)
}

export { validateNumbers, checkDocumentFormat, checkNotRealNumber, FINANCING_PRODUCTS, ENABLE_VALIDATIONS }
