import InputMask from "react-input-mask"
import MyTextField from "components/base/TextField"
import useInput from "hooks/use_input"
import { validateNumbers } from "config/validations"

const MobilePhone = () => {
  const id = "mobile_phone"

  const validate = (value) => {
    if (value === "") return "This field is required."

    if (!validateNumbers(value)) return "Please enter valid phone number"

    if (value.replace(/\D/g, "").length < 11) return "Should contain 11 numbers"

    return ""
  }

  const { handleChange, inputValue, errorMessage, handleBlur, focused, handleFocus } = useInput(id, validate)

  // const mask = [
  //   "+",
  //   "6",
  //   "3",
  //   " ",
  //   "(",
  //   /[0-9]/,
  //   /[0-9]/,
  //   /[0-9]/,
  //   ")",
  //   " ",
  //   /[0-9]/,
  //   /[0-9]/,
  //   /[0-9]/,
  //   " ",
  //   /[0-9]/,
  //   /[0-9]/,
  //   /[0-9]/,
  //   /[0-9]/,
  // ]

  const mask = ["0", "9", /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

  return (
    // https://blog.logrocket.com/implementing-react-input-mask-web-apps/
    <InputMask
      required
      mask={mask}
      maskPlaceholder="_"
      alwaysShowMask
      value={inputValue}
      onChange={handleChange}
      error={errorMessage?.length > 0}
      onBlur={handleBlur}
      // onMouseLeave={handleBlur}
      onFocus={handleFocus}
      focused={focused}
      helperText={errorMessage}
    >
      <MyTextField id={id} _label="Phone number" inputProps={{ inputMode: "numeric" }} />
    </InputMask>
  )
}
export default MobilePhone
